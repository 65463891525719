import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
// components
import { SplashScreen } from 'src/components/loading-screen';
import AuthClassicLayout from 'src/layouts/auth/classic';
import CompactLayout from 'src/layouts/compact';
// ----------------------------------------------------------------------

// JWT
const LoginPage = lazy(() => import('src/pages/auth/login'));
const RegisterPage = lazy(() => import('src/pages/auth/register'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password'));
const VerifyPage = lazy(() => import('src/pages/auth/verify'));
const NewPasswordPage = lazy(() => import('src/pages/auth/new-password'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: (
          <CompactLayout>
            <LoginPage />
          </CompactLayout>
        ),
      },
      {
        path: 'register',
        element: (
          <CompactLayout>
            <RegisterPage />
          </CompactLayout>
        ),
      },
      {
        element: (
          <CompactLayout>
            <Outlet />
          </CompactLayout>
        ),
        children: [
          { path: 'forgot-password', element: <ForgotPasswordPage /> },
          { path: 'new-password', element: <NewPasswordPage /> },
          { path: 'verify', element: <VerifyPage /> },
        ],
      },
    ],
  }
];
