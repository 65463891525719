/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReportType {
    SALES_GROUP_BY_MERCHANT = 'SalesGroupByMerchant',
    REDEEMED_GROUP_BY_MERCHANT = 'RedeemedGroupByMerchant',
    UN_REDEEMED_VOUCHERS = 'UnRedeemedVouchers',
}
