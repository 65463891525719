import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { ApiError, MerchantReportDto, MerchantReportService } from 'src/api';

export interface MerchantReportState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  merchantReports: MerchantReportDto[];
}

const initialState: MerchantReportState = {
  loading: false,
  hasError: false,
  error: '',
  merchantReports: [],
};

export const merchantReportSlice = createSlice({
  name: 'merchantReport',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.hasError = false;
      }
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setMerchantReports: (state, action: PayloadAction<MerchantReportDto[]>) => {
      state.merchantReports = action.payload;
    },
    setMerchantReport: (state, action: PayloadAction<MerchantReportDto>) => {
      state.merchantReports = state.merchantReports.map((m) => {
        if (m.id === action.payload) return action.payload;
        return m;
      });
    },
    addMerchantReport: (state, action: PayloadAction<MerchantReportDto>) => {
      state.merchantReports.push(action.payload);
    },
    updateMerchantReport: (state, action: PayloadAction<MerchantReportDto>) => {
      state.merchantReports = state.merchantReports.map((merchantReport) =>
        merchantReport.id === action.payload.id ? action.payload : merchantReport
      );
    },
    removeMerchantReport: (state, action: PayloadAction<string>) => {
      state.merchantReports = state.merchantReports.filter((sr) => sr.id !== action.payload);
    },
  },
});

export const {
  loading,
  hasError,
  setMerchantReports,
  setMerchantReport,
  addMerchantReport,
  updateMerchantReport,
  removeMerchantReport,
} = merchantReportSlice.actions;

export const getMerchantReportsAsync = (merchantId: string): AppThunk => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await MerchantReportService.getApiMerchantReport(merchantId);
    dispatch(setMerchantReports(response));
  } catch (e) {
    dispatch(hasError((e as ApiError).body));
  } finally {
    dispatch(loading(false));
  }
};

export const getMerchantReportAsync =
  (merchantId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await MerchantReportService.getApiMerchantReport1(merchantId);
      dispatch(setMerchantReport(response));
      return response;
    } catch (e) {
      dispatch(hasError((e as ApiError).body));
      throw e;
    } 
  };

export const createMerchantReportsAsync =
  (merchantId: string, startDate: number, endDate: number): AppThunk =>
  async (dispatch) => {
    try {
      const response = await MerchantReportService.postApiMerchantReport(
        merchantId,
        startDate,
        endDate
      );
      dispatch(addMerchantReport(response));
      return true;
    } catch (e) {
      dispatch(hasError((e as ApiError).body));
      throw e;
    }
  };

export const removeMerchantReportAsync =
  (merchantReportId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await MerchantReportService.deleteApiMerchantReport(merchantReportId);
      dispatch(removeMerchantReport(merchantReportId));
      return true;
    } catch (e) {
      dispatch(hasError((e as ApiError).body));
      throw e;
    }
  };

  export const publishMerchantReportAsync =
  (merchantReportId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await MerchantReportService.postApiMerchantReportPublish(merchantReportId);
      dispatch(updateMerchantReport(response));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
    return true;
  };

export const merchantReportStateSelector = (state: RootState): MerchantReportState =>
  state.merchantReport;

export default merchantReportSlice.reducer;
