import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import {
  ApiError,
  GreetieDto,
  GreetieService,
  VoucherDto,
  
} from 'src/api';
import { IErrorDetails } from 'src/types/errorDetails';


export interface GreetieState {
  loading: boolean;
  greeties: GreetieDto[];
  redeeming: boolean;
  hasError: boolean;
  greetieError?: string;
  userId: string;
  greetie: GreetieDto | null;
  selectedVoucher: VoucherDto | null;
}

const initialState: GreetieState = {
  loading: false,
  greeties: [],
  redeeming: false,
  hasError: false,
  greetieError: '',
  userId: '',
  greetie: null,
  selectedVoucher: null,
};

export const greetieSlice = createSlice({
  name: 'greetie',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.hasError = false;
        state.greetieError = '';
      }
      state.loading = action.payload;
    },
    redeeming: (state, action: PayloadAction<boolean>) => {
      state.redeeming = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.greetieError = `${action.payload}`;
    },
    setGreetie: (state, action: PayloadAction<GreetieDto>) => {
      state.greetie = action.payload;
    },
    setGreeties: (state, action: PayloadAction<GreetieDto[]>) => {
      state.greeties = action.payload;
    }, 
    setSelectedVoucher: (state, action: PayloadAction<VoucherDto>) => {
      state.selectedVoucher = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
  },
});

export const {
  loading,
  redeeming,
  hasError,
  setGreetie,
  setGreeties,
  setSelectedVoucher,
  setUserId,
} = greetieSlice.actions;


export const getGreetiesAsync =
  (): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await GreetieService.getApiGreetie();
      dispatch(setGreeties(response));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError(((e as ApiError).body as IErrorDetails).message));
    }
  };

export const getGreetieAsync =
  (greetieId: string, userId: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const result = await GreetieService.getApiGreetie1(greetieId);
      dispatch(setGreetie(result));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError(((e as ApiError).body as IErrorDetails).message));
    }
  };

export const greetieStateSelector = (state: RootState): GreetieState => state.greetie;

export default greetieSlice.reducer;
