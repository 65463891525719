/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GreetieInfoDto } from '../models/GreetieInfoDto';
import type { MobileUserDto } from '../models/MobileUserDto';
import type { UpdateMobileUserDto } from '../models/UpdateMobileUserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MobileUserService {

    /**
     * @returns MobileUserDto OK
     * @throws ApiError
     */
    public static getApiMobileuser(): CancelablePromise<Array<MobileUserDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mobileuser',
        });
    }

    /**
     * @param id 
     * @returns MobileUserDto OK
     * @throws ApiError
     */
    public static getApiMobileuser1(
id: string,
): CancelablePromise<MobileUserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mobileuser/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns MobileUserDto OK
     * @throws ApiError
     */
    public static postApiMobileuser(
id: string,
requestBody?: UpdateMobileUserDto,
): CancelablePromise<MobileUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mobileuser/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @param title 
     * @param body 
     * @param url 
     * @returns boolean OK
     * @throws ApiError
     */
    public static postApiMobileuserMessage(
id: string,
title?: string,
body?: string,
url?: string,
): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mobileuser/{id}/message',
            path: {
                'id': id,
            },
            query: {
                'title': title,
                'body': body,
                'url': url,
            },
        });
    }

    /**
     * @param id 
     * @returns MobileUserDto OK
     * @throws ApiError
     */
    public static postApiMobileuserActivateBeta(
id: string,
): CancelablePromise<MobileUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/mobileuser/{id}/activateBeta',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns GreetieInfoDto OK
     * @throws ApiError
     */
    public static getApiMobileuserGreeties(
id: string,
): CancelablePromise<Array<GreetieInfoDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/mobileuser/{id}/greeties',
            path: {
                'id': id,
            },
        });
    }

}
