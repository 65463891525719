/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductStatsDto } from '../models/ProductStatsDto';
import type { StatisticsDto } from '../models/StatisticsDto';
import type { StatisticsItemDto } from '../models/StatisticsItemDto';
import type { YearlySalesDto } from '../models/YearlySalesDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * @returns StatisticsDto OK
     * @throws ApiError
     */
    public static getApiStatistics(): CancelablePromise<StatisticsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Statistics',
        });
    }

    /**
     * @returns ProductStatsDto OK
     * @throws ApiError
     */
    public static getApiStatisticsProducttoplist(): CancelablePromise<Array<ProductStatsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Statistics/producttoplist',
        });
    }

    /**
     * @returns ProductStatsDto OK
     * @throws ApiError
     */
    public static getApiStatisticsSoldproducttoplist(): CancelablePromise<Array<ProductStatsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Statistics/soldproducttoplist',
        });
    }

    /**
     * @returns YearlySalesDto OK
     * @throws ApiError
     */
    public static getApiStatisticsYearlysales(): CancelablePromise<Array<YearlySalesDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Statistics/yearlysales',
        });
    }

    /**
     * @returns StatisticsItemDto OK
     * @throws ApiError
     */
    public static getApiStatisticsRedeemed(): CancelablePromise<StatisticsItemDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Statistics/redeemed',
        });
    }

}
