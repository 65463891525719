import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';
import { UserDto, UserService } from 'src/api';

export interface MerchantUserState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  users: UserDto[];
  user: UserDto | null;
}

const initialState: MerchantUserState = {
  users: [],
  user: null,
  loading: false,
  hasError: false,
  error: '',
};

export const merchantUserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setUsers: (state, action: PayloadAction<UserDto[]>) => {
      state.users = action.payload;
    },
    update: (state, action: PayloadAction<UserDto>) => {
      state.user = action.payload;
      state.users = state.users.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    },
    saving: (state) => {
      state.hasError = false;
      state.error = '';
    },
  },
});

export const { loading, saving, hasError, update, setUsers } = merchantUserSlice.actions;

export const getUsersAsync = (merchantId: string): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loading(true));
    const data = await UserService.getApiUser(merchantId)
    dispatch(setUsers(data));
    dispatch(loading(false));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const onUpdateUser =
  (user: UserDto): AppThunk =>
  async (dispatch) => {
    try {

      dispatch(saving());
      const userResult = await UserService.putApiUser(user!.id!, user);
      dispatch(update(userResult));
     
    } catch (e) {
      dispatch(hasError((e as Error).message));
      return false
    }
    return true;
  };

  export const onCreateUser =
  (user: UserDto): AppThunk =>
  async (dispatch) => {
    try {

      dispatch(saving());
      const userResult = await UserService.postApiUser(user);
      dispatch(update(userResult));
     
    } catch (e) {
      dispatch(hasError((e as Error).message));
      return false
    }
    return true;
  };

export const onRemoveUser =
  (eventId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      // const result = await UsersService.deleteApiUsers(eventId);

      // dispatch(update(result));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const merchantUserStateSelector = (state: RootState): MerchantUserState => state.merchantuser;

export default merchantUserSlice.reducer;
