/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProductCodeDisplay {
    TEXT = 'Text',
    QR = 'QR',
    TEXT_AND_QR = 'TextAndQR',
}
