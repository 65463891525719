import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ProductStatsDto, StatisticsDto, StatisticsItemDto, StatisticsService, YearlySalesDto } from 'src/api';
import type { AppThunk, RootState } from 'src/store/index';

export interface StatisticsState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  statistics: StatisticsDto | null;
  productToplist: ProductStatsDto[];
  soldProductToplist: ProductStatsDto[];
  yearlySales: YearlySalesDto[];
  redeemed: StatisticsItemDto | null;
}

const initialState: StatisticsState = {
  loading: false,
  hasError: false,
  error: '',
  statistics: null,
  productToplist: [],
  soldProductToplist: [],
  yearlySales: [],
  redeemed: null
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setStatistics: (state, action: PayloadAction<StatisticsDto>) => {
      state.statistics = action.payload;
    },
    setProductToplist: (state, action: PayloadAction<ProductStatsDto[]>) => {
      state.productToplist = action.payload;
    },
    setSoldProductToplist: (state, action: PayloadAction<ProductStatsDto[]>) => {
      state.soldProductToplist = action.payload;
    },
    setYearlySales: (state, action: PayloadAction<YearlySalesDto[]>) => {
      state.yearlySales = action.payload;
    },
    setRedeemed: (state, action: PayloadAction<StatisticsItemDto>) => {
      state.redeemed = action.payload;
    },
  },
});

export const { loading, hasError, setStatistics, setProductToplist, setYearlySales, setRedeemed, setSoldProductToplist } = statisticsSlice.actions;

export const getStatisticsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loading(true));
    const data = await StatisticsService.getApiStatistics();
    dispatch(setStatistics(data));
    dispatch(loading(false));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const getProductToplistAsync = (): AppThunk => async (dispatch) => {
  try {
    const data = await StatisticsService.getApiStatisticsProducttoplist();
    dispatch(setProductToplist(data));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const getSoldProductToplistAsync = (): AppThunk => async (dispatch) => {
  try {
    const data = await StatisticsService.getApiStatisticsSoldproducttoplist();
    dispatch(setSoldProductToplist(data));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const getYearlySalesAsync = (): AppThunk => async (dispatch) => {
  try {
    const data = await StatisticsService.getApiStatisticsYearlysales();
    dispatch(setYearlySales(data));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const getRedeemedAsync = (): AppThunk => async (dispatch) => {
  try {
    const data = await StatisticsService.getApiStatisticsRedeemed();
    dispatch(setRedeemed(data));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export default statisticsSlice.reducer;

export const statisticsStateSelector = (state: RootState): StatisticsState => state.statistics;
