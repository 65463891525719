/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MerchantReportDto } from '../models/MerchantReportDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MerchantReportService {

    /**
     * @param merchantId 
     * @returns MerchantReportDto OK
     * @throws ApiError
     */
    public static getApiMerchantReport(
merchantId?: string,
): CancelablePromise<Array<MerchantReportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/MerchantReport',
            query: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param merchantId 
     * @param startAt 
     * @param endAt 
     * @returns MerchantReportDto OK
     * @throws ApiError
     */
    public static postApiMerchantReport(
merchantId?: string,
startAt?: number,
endAt?: number,
): CancelablePromise<MerchantReportDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/MerchantReport',
            query: {
                'merchantId': merchantId,
                'startAt': startAt,
                'endAt': endAt,
            },
        });
    }

    /**
     * @param id 
     * @returns MerchantReportDto OK
     * @throws ApiError
     */
    public static getApiMerchantReport1(
id: string,
): CancelablePromise<MerchantReportDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/MerchantReport/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns number OK
     * @throws ApiError
     */
    public static deleteApiMerchantReport(
id: string,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/MerchantReport/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns MerchantReportDto OK
     * @throws ApiError
     */
    public static postApiMerchantReportPublish(
id: string,
): CancelablePromise<MerchantReportDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/MerchantReport/{id}/publish',
            path: {
                'id': id,
            },
        });
    }

}
