import type { ButtonBaseProps } from '@mui/material/ButtonBase';

import { useState, useCallback, useContext } from 'react';

import { MerchantDto, MerchantInfoDto } from 'src/api';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import orderBy from 'lodash/orderBy';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { WorkspaceContext, WorkspaceContextType } from 'src/context/workspace-context';

// ----------------------------------------------------------------------

export type WorkspacesPopoverProps = ButtonBaseProps & {
  data?: MerchantInfoDto[] | null;
};

export function WorkspacesPopover({ data = [], sx, ...other }: WorkspacesPopoverProps) {
  const popover = usePopover();

  const mediaQuery = 'sm';

  const { workspace, onChangeWorkspace } = useContext(WorkspaceContext) as WorkspaceContextType;

  const handleChangeWorkspace = useCallback(
    (newValue: MerchantDto) => {
      onChangeWorkspace(newValue);
      popover.onClose();
    },
    [popover, onChangeWorkspace]
  );

  return (
    <>
      <ButtonBase
        disableRipple
        onClick={popover.onOpen}
        sx={{
          py: 0.5,
          gap: { xs: 0.5, [mediaQuery]: 1 },
          ...sx,
        }}
        {...other}
      >
        <Box
          component="img"
          alt={workspace?.name!}
          src={workspace?.logo?.url!}
          sx={{ width: 24, height: 24, borderRadius: '50%' }}
        />

        <Box
          component="span"
          sx={{
            typography: 'subtitle2',
            display: { xs: 'none', [mediaQuery]: 'inline-flex' },
            color: 'black',
          }}
        >
          {workspace?.name}
        </Box>

        <Iconify width={16} icon="carbon:chevron-sort" sx={{ color: 'text.disabled' }} />
      </ButtonBase>

      <CustomPopover
        open={popover.open}
        // anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        arrow="top-left"
      >
        <MenuList sx={{ width: 240 }}>
          {orderBy(data, ['name']).map((option) => (
            <MenuItem
              key={option.id}
              selected={option.id === workspace?.id}
              onClick={() => handleChangeWorkspace(option)}
              sx={{ height: 48 }}
            >
              <Avatar alt={option.name!} src={option.logo?.url!} sx={{ width: 24, height: 24, mr:"5px" }} />

              <Box component="span" sx={{ flexGrow: 1 }}>
                {option.name}
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </CustomPopover>
    </>
  );
}
