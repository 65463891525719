/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PaymentStatus {
    INITIATED = 'Initiated',
    CANCELLED = 'Cancelled',
    DECLINED = 'Declined',
    ERROR = 'Error',
    PAID = 'Paid',
}
