/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SalesReportDto } from '../models/SalesReportDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SalesReportService {

    /**
     * @returns SalesReportDto OK
     * @throws ApiError
     */
    public static getApiSalesReport(): CancelablePromise<Array<SalesReportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/SalesReport',
        });
    }

    /**
     * @param startAt 
     * @param endAt 
     * @returns SalesReportDto OK
     * @throws ApiError
     */
    public static postApiSalesReport(
startAt?: number,
endAt?: number,
): CancelablePromise<SalesReportDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/SalesReport',
            query: {
                'startAt': startAt,
                'endAt': endAt,
            },
        });
    }

    /**
     * @param id 
     * @returns SalesReportDto OK
     * @throws ApiError
     */
    public static getApiSalesReport1(
id: string,
): CancelablePromise<SalesReportDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/SalesReport/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns number OK
     * @throws ApiError
     */
    public static deleteApiSalesReport(
id: string,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/SalesReport/{id}',
            path: {
                'id': id,
            },
        });
    }

}
