import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import {
  ApiError,
  ReportDto,
  ReportService,
  ReportType,
  SalesReportDto,
  SalesReportService,
} from 'src/api';

export interface ReportState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  reports: ReportDto[];
  currentReport?: ReportDto | null;
}

const initialState: ReportState = {
  loading: false,
  hasError: false,
  error: '',
  reports: [],
  currentReport: null,
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.hasError = false;
      }
      state.loading = action.payload;
    },

    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setReports: (state, action: PayloadAction<ReportDto[]>) => {
      state.reports = action.payload;
    },
    setReport: (state, action: PayloadAction<ReportDto>) => {
      state.currentReport = action.payload;
    },
    addReport: (state, action: PayloadAction<ReportDto>) => {
      state.reports.push(action.payload);
    },
    removeReport: (state, action: PayloadAction<string>) => {
      state.reports = state.reports.filter((sr) => sr.id !== action.payload);
    },
  },
});

export const { loading, hasError, setReport, setReports, addReport, removeReport } =
  reportSlice.actions;

export const getReportsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loading(true));
    const response = await ReportService.getApiReport();
    dispatch(setReports(response));
  } catch (e) {
    dispatch(hasError((e as ApiError).body));
  } finally {
    dispatch(loading(false));
  }
};

export const getReport =
  (reportId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await ReportService.getApiReport1(reportId);
      dispatch(setReport(response));
    } catch (e) {
      dispatch(hasError((e as ApiError).body));
    } 
  };

export const createReportsAsync =
  (startDate: number, endDate: number, reportType: ReportType): AppThunk =>
  async (dispatch) => {
    try {
      const response = await ReportService.postApiReport(startDate, endDate, reportType);
      dispatch(addReport(response));
      return true;
    } catch (e) {
      dispatch(hasError((e as ApiError).body));
      throw e;
    }
  };

export const removeReportAsync =
  (reportId: string): AppThunk =>
  async (dispatch) => {
    try {
      const response = await ReportService.deleteApiReport(reportId);
      dispatch(removeReport(reportId));
      return true;
    } catch (e) {
      dispatch(hasError((e as ApiError).body));
      throw e;
    }
  };

export const reportStateSelector = (state: RootState): ReportState => state.report;

export default reportSlice.reducer;
