import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';
import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';
import { UserDto, UserService } from 'src/api';

export interface AccountFormState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  user: UserDto | null;
}

const initialState: AccountFormState = {
  loading: false,
  hasError: false,
  error: '',
  user: null,
};

export const accountFormSlice = createSlice({
  name: 'accountForm',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    saving: (state) => {
      state.hasError = false;
      state.error = '';
    },
    update: (state, action: PayloadAction<UserDto>) => {
      state.user = action.payload;
    },
  },
});

export const { loading, saving, hasError, update } = accountFormSlice.actions;

export const loadAccountAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const data = await UserService.getApiUserMy();
      dispatch(update(data));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const onUpdateUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchStart());

    dispatch(saving());
    // const userResult = await ClubsService.putApiUsers(user!.id!, user);

    // dispatch(update(userResult));
    dispatch(fetchSuccess());
  } catch (e) {
    dispatch(hasError((e as Error).message));
    dispatch(fetchError((e as Error).message));
  }
  return true;
};

export default accountFormSlice.reducer;

export const accountFormStateSelector = (state: RootState): AccountFormState => state.accountForm;
