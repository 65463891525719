import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { MarketingDto, MarketingService } from 'src/api';

export interface MarketingState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  marketingMessages: MarketingDto[];
  currentMarketingMessage: MarketingDto | null;
  marketingMessagesEmpty: boolean;
}

const initialState: MarketingState = {
  marketingMessages: [],
  currentMarketingMessage: null,
  loading: false,
  hasError: false,
  error: '',
  marketingMessagesEmpty: true,
};

export const marketingSlice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setCurrentMarketingMessage: (state, action: PayloadAction<MarketingDto>) => {
      state.currentMarketingMessage = action.payload;
    },
    setMarketingMessages: (state, action: PayloadAction<MarketingDto[]>) => {
      state.marketingMessages = action.payload;
      state.marketingMessagesEmpty = action.payload.length === 0;
    },
    update: (state, action: PayloadAction<MarketingDto>) => {
      state.currentMarketingMessage = action.payload;
      state.marketingMessages = state.marketingMessages.map((product) =>
        product.id === action.payload.id ? action.payload : product
      );
    },
    addMarketingMessage: (state, action: PayloadAction<MarketingDto>) => {
      state.marketingMessages.push(action.payload);
    },
    removeMarketingMessage: (state, action: PayloadAction<string>) => {
      state.marketingMessages = state.marketingMessages.filter((sr) => sr.id !== action.payload);
    },

    saving: (state) => {
      state.hasError = false;
      state.error = '';
    },
  },
});

export const {
  loading,
  saving,
  hasError,
  update,
  setCurrentMarketingMessage,
  setMarketingMessages,
  addMarketingMessage,
  removeMarketingMessage,
} = marketingSlice.actions;

export const getMarketingMessagesAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loading(true));
    const data = await MarketingService.getApiMarketing();
    dispatch(setMarketingMessages(data));
    dispatch(loading(false));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const getMarketingMessageAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const data = await MarketingService.getApiMarketing1(id);
      dispatch(setCurrentMarketingMessage(data));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const createMarketingMessage =
  (marketingMessage: MarketingDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await MarketingService.postApiMarketing(marketingMessage);
      dispatch(addMarketingMessage(result));

      return result;
    } catch (e) {
      console.log(e);
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const updateMarketingMessage =
  (marketingMessage: MarketingDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await MarketingService.putApiMarketing(marketingMessage.id!, marketingMessage);
      dispatch(update(result));

      return result;
    } catch (e) {
      console.log(e);
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const deleteMarketingMessage =
  (marketingId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const result = await MarketingService.deleteApiMarketing(marketingId);
      dispatch(removeMarketingMessage(marketingId));

      return result;
    } catch (e) {
      console.log(e);
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const marketingStateSelector = (state: RootState): MarketingState => state.marketing;

export default marketingSlice.reducer;
