/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProductCategory {
    FOOD = 'Food',
    BEAUTY_AND_HEALTH = 'BeautyAndHealth',
    SPORT = 'Sport',
    ENTERTAINMENT = 'Entertainment',
    TRAVEL = 'Travel',
    CHARITY = 'Charity',
    HOME = 'Home',
}
