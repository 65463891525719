// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    newPassword: `${ROOTS.AUTH}/new-password`,
    verify: `${ROOTS.AUTH}/verify`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    app: `${ROOTS.DASHBOARD}/app`,
    admin: `${ROOTS.DASHBOARD}/admin`,
  },
  user: {
    root: `/user`,
    new: `/user/new`,
    list: `/user/list`,
    cards: `/user/cards`,
    account: (id: string) => `/user/${id}/account`,
    edit: (id: string) => `/user/${id}/edit`,
  },
  merchant: {
    root: `/merchant`,
    new: (id?: string) => (id ? `/merchant/new/${id}` : `/merchant/new`),
    details: '/merchant/details',
    edit: (id: string) => `/merchant/${id}/edit`,
  },
  product: {
    root: `/product`,
    new: (id?: string) => (id ? `/product/new/${id}` : `/product/new`),
    details: (id: string) => `/product/details/${id}`,
    edit: (id: string) => `/product/${id}/edit`,
  },
  greetie: {
    root: `/greetie`,
    new: `/greetie/new`,
    details: (id: string) => `/greetie/details/${id}`,
  },
  mobileuser: {
    root: `/mobileuser`,
    list: `/mobileuser/list`,
    edit: (id: string) => `/mobileuser/${id}/edit`,
    details: (id: string) => `/mobileuser/details/${id}`,
  },
  voucher: {
    root: `/voucher`,
    details: (id: string) => `/voucher/details/${id}`,
  },
  salesreport: {
    root: `/salesreport`,
    list: `/salesreport/list`,
    new: `/salesreport/new`,
    details: (id: string) => `/salesreport/${id}`,
    edit: (id: string) => `/salesreport/${id}/edit`,
  },
  payment: {
    root: `/payment`,
    list: `/payment/list`,
    details: (id: string) => `/payment/${id}`,
  },
  redeem: {
    root: `/redeem`,
    details: (id: string) => `/redeem/details/${id}`,
  },
  merchantreport: {
    root: `/merchantreport`,
    list: `/merchantreport/list`,
    details: (id: string) => `/merchantreport/${id}`,
  },
  merchantuser: {
    root: `/merchantuser`,
    new: `/merchantuser/new`,
    list: `/merchantuser/list`,
    cards: `/merchantuser/cards`,
    account: (id: string) => `/merchantuser/${id}/account`,
    edit: (id: string) => `/merchantuser/${id}/edit`,
  },
  marketing: {
    root: `/marketing`,
    list: `/marketing/list`,
    details: (id: string) => `/marketing/${id}`,
  },
  productCode: {
    root: `/productcode`,
    list: `/productcode/list`,
    details: (id: string) => `/productcode/details/${id}`,
   
  },
  report: {
    root: `/report`,
    list: `/report/list`,
    details: (id: string) => `/report/${id}`,
  },
};
