import { PayloadAction, createSlice } from '@reduxjs/toolkit';


export interface AuthState {
  loading: boolean;
  hasError: boolean;
  error?: string;
}

const initialState: AuthState = { loading: false, hasError: false, error: '' };

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
  },
});

export const { hasError } = authSlice.actions;

export default authSlice.reducer;
