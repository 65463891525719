import { createContext, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { MerchantInfoDto } from 'src/api';

export type WorkspaceContextType = {
  workspace: MerchantInfoDto | undefined;
  onChangeWorkspace: (workspace: MerchantInfoDto) => void;
};

export const WorkspaceContext = createContext<WorkspaceContextType | null>(null);

type Props = {
  children: React.ReactNode;
};

export function WorkspaceProvider({ children }: Props) {
  const [selectedWorkspace, setWorkspace] = useState<MerchantInfoDto | undefined>(undefined);

  const initialize = useCallback(async () => {
    const savedWorkspace = localStorage.getItem('WORKSPACE');

    if (savedWorkspace) {
      setWorkspace(JSON.parse(savedWorkspace));
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const onChangeWorkspace = useCallback(async (workspace: MerchantInfoDto) => {
    if (workspace) {
      setWorkspace(workspace);
      localStorage.setItem('WORKSPACE', JSON.stringify(workspace));
    } else {
      localStorage.removeItem('WORKSPACE');
    }
  }, []);

  // ----------------------------------------------------------------------

  const memoizedValue = useMemo(
    () => ({
      workspace: selectedWorkspace,
      //
      onChangeWorkspace,
    }),
    [selectedWorkspace, onChangeWorkspace]
  );

  return <WorkspaceContext.Provider value={memoizedValue}>{children}</WorkspaceContext.Provider>;
}
