/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MarketingDto } from '../models/MarketingDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MarketingService {

    /**
     * @returns MarketingDto OK
     * @throws ApiError
     */
    public static getApiMarketing(): CancelablePromise<Array<MarketingDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Marketing',
        });
    }

    /**
     * @param requestBody 
     * @returns MarketingDto OK
     * @throws ApiError
     */
    public static postApiMarketing(
requestBody?: MarketingDto,
): CancelablePromise<MarketingDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Marketing',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns MarketingDto OK
     * @throws ApiError
     */
    public static getApiMarketing1(
id: string,
): CancelablePromise<MarketingDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Marketing/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns MarketingDto OK
     * @throws ApiError
     */
    public static putApiMarketing(
id: string,
requestBody?: MarketingDto,
): CancelablePromise<MarketingDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Marketing/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns number OK
     * @throws ApiError
     */
    public static deleteApiMarketing(
id: string,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Marketing/{id}',
            path: {
                'id': id,
            },
        });
    }

}
