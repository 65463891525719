/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MerchantType {
    BUSINESS = 'Business',
    CHARITY = 'Charity',
    ONLINE = 'Online',
}
