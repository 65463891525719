/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReportDto } from '../models/ReportDto';
import type { ReportType } from '../models/ReportType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportService {

    /**
     * @returns ReportDto OK
     * @throws ApiError
     */
    public static getApiReport(): CancelablePromise<Array<ReportDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Report',
        });
    }

    /**
     * @param startAt 
     * @param endAt 
     * @param reportType 
     * @returns ReportDto OK
     * @throws ApiError
     */
    public static postApiReport(
startAt?: number,
endAt?: number,
reportType?: ReportType,
): CancelablePromise<ReportDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Report',
            query: {
                'startAt': startAt,
                'endAt': endAt,
                'reportType': reportType,
            },
        });
    }

    /**
     * @param id 
     * @returns ReportDto OK
     * @throws ApiError
     */
    public static getApiReport1(
id: string,
): CancelablePromise<ReportDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Report/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns number OK
     * @throws ApiError
     */
    public static deleteApiReport(
id: string,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Report/{id}',
            path: {
                'id': id,
            },
        });
    }

}
