import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { ApiError, MerchantService, VoucherDto } from 'src/api';

export interface RedeemState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  vouchers: VoucherDto[];
}

const initialState: RedeemState = {
  loading: false,
  hasError: false,
  error: '',
  vouchers: [],
};

export const redeemSlice = createSlice({
  name: 'redeem',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.hasError = false;
      }
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setVouchers: (state, action: PayloadAction<VoucherDto[]>) => {
      state.vouchers = action.payload;
    },
    reset: (state) => {
      state.loading = false;
      state.hasError = false;
      state.error = '';
      state.vouchers = [];
    },
  },
});

export const { loading, hasError, setVouchers, reset } = redeemSlice.actions;

export const getRedeemsAsync =
  (merchantId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await MerchantService.getApiMerchantRedeems(merchantId);
      dispatch(setVouchers(response));
    } catch (e) {
      dispatch(hasError((e as ApiError).body));
    } finally {
      dispatch(loading(false));
    }
  };

export const redeemStateSelector = (state: RootState): RedeemState => state.redeem;

export default redeemSlice.reducer;
