/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ProductType {
    IN_STORE = 'InStore',
    CHARITY = 'Charity',
    CODE = 'Code',
    GIFT_CARD = 'GiftCard',
}
