/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VATRate {
    VAT25 = 'Vat25',
    VAT12 = 'Vat12',
    VAT6 = 'Vat6',
    VAT0 = 'Vat0',
}
