/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GreetieDto } from '../models/GreetieDto';
import type { PaymentDto } from '../models/PaymentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GreetieService {

    /**
     * @returns GreetieDto OK
     * @throws ApiError
     */
    public static getApiGreetie(): CancelablePromise<Array<GreetieDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/greetie',
        });
    }

    /**
     * @param id 
     * @returns GreetieDto OK
     * @throws ApiError
     */
    public static getApiGreetie1(
id: string,
): CancelablePromise<GreetieDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/greetie/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns PaymentDto OK
     * @throws ApiError
     */
    public static getApiGreetiePayment(
id: string,
): CancelablePromise<PaymentDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/greetie/{id}/payment',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns GreetieDto OK
     * @throws ApiError
     */
    public static deleteApiGreetieDeleteUnPaid(): CancelablePromise<GreetieDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/greetie/deleteUnPaid',
        });
    }

}
