/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductCodeDto } from '../models/ProductCodeDto';
import type { ShopifyGiftCardRequestDto } from '../models/ShopifyGiftCardRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductCodeService {

    /**
     * @param merchantId 
     * @returns ProductCodeDto OK
     * @throws ApiError
     */
    public static getApiProductcode(
merchantId?: string,
): CancelablePromise<Array<ProductCodeDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/productcode',
            query: {
                'merchantId': merchantId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ProductCodeDto OK
     * @throws ApiError
     */
    public static postApiProductcode(
requestBody?: ProductCodeDto,
): CancelablePromise<ProductCodeDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/productcode',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ProductCodeDto OK
     * @throws ApiError
     */
    public static getApiProductcode1(
id: string,
): CancelablePromise<ProductCodeDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/productcode/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns ProductCodeDto OK
     * @throws ApiError
     */
    public static putApiProductcode(
id: string,
requestBody?: ProductCodeDto,
): CancelablePromise<ProductCodeDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/productcode/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns number OK
     * @throws ApiError
     */
    public static deleteApiProductcode(
id: string,
): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/productcode/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ProductCodeDto OK
     * @throws ApiError
     */
    public static postShopify(
requestBody?: ShopifyGiftCardRequestDto,
): CancelablePromise<Array<ProductCodeDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/shopify',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
