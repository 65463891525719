import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';
import {
  AddressDto,
  AddressesDto,
  AgreementDto,
  AssetDto,
  IntegrationDto,
  MerchantDto,
  MerchantProductDto,
  MerchantService,
} from 'src/api';

export interface MerchantState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  merchants: MerchantDto[];
  products: MerchantProductDto[];
  currentMerchant: MerchantDto | null;
}

const initialState: MerchantState = {
  merchants: [],
  products: [],
  currentMerchant: null,
  loading: false,
  hasError: false,
  error: '',
};

export const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    loadingMerchant: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      state.currentMerchant = null;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setMerchants: (state, action: PayloadAction<MerchantDto[]>) => {
      state.merchants = action.payload;
    },
    setCurrentMerchant: (state, action: PayloadAction<MerchantDto>) => {
      state.currentMerchant = action.payload;
    },
    setProducts: (state, action: PayloadAction<MerchantProductDto[]>) => {
      state.products = action.payload;
    },
    update: (state, action: PayloadAction<MerchantDto>) => {
      state.currentMerchant = action.payload;
      state.merchants = state.merchants.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    },
    saving: (state) => {
      state.hasError = false;
      state.error = '';
    },
    setMerchantAgreements: (
      state,
      action: PayloadAction<{ id: string; agreements: AgreementDto[] }>
    ) => {
      const updatedCurrentMerchant = { ...state.currentMerchant };
      updatedCurrentMerchant.agreements = action.payload.agreements;
      state.currentMerchant = updatedCurrentMerchant;

      state.merchants = state.merchants.map((merchant) => {
        if (merchant.id === action.payload.id) {
          const updatedMerchant = { ...merchant };
          updatedMerchant.agreements = action.payload.agreements;
          return updatedMerchant;
        }
        return merchant;
      });
    },
    setMerchantLogo: (state, action: PayloadAction<AssetDto>) => {
      const currentMerchant = { ...state.currentMerchant };
      currentMerchant.logo = action.payload;
      state.currentMerchant = currentMerchant;

      state.merchants = state.merchants.map((merchant) => {
        if (merchant.id === currentMerchant.id) {
          const updatedMerchant = { ...merchant };
          updatedMerchant.logo = action.payload;
          return updatedMerchant;
        }
        return merchant;
      });
    },
    removeMerchant: (state, action: PayloadAction<string>) => {
      state.merchants = state.merchants.filter((m) => m.id !== action.payload);
    },
    setMerchantIntegrations: (
      state,
      action: PayloadAction<{ id: string; integration: IntegrationDto }>
    ) => {
      const updatedCurrentMerchant = { ...state.currentMerchant };
      updatedCurrentMerchant.integrations = updatedCurrentMerchant.integrations?.map((i) => {
        if (i.id === action.payload.id) {
          return action.payload.integration;
        }
        return i;
      });

      console.log(updatedCurrentMerchant);
      state.currentMerchant = updatedCurrentMerchant;
    },
    removeMerchantIntegrations: (state, action: PayloadAction<string>) => {
      const updatedCurrentMerchant = { ...state.currentMerchant };
      updatedCurrentMerchant.integrations = updatedCurrentMerchant.integrations?.filter(
        (i) => i.id !== action.payload
      );

      console.log(updatedCurrentMerchant);
      state.currentMerchant = updatedCurrentMerchant;
    },
  },
});

export const {
  loading,
  loadingMerchant,
  saving,
  hasError,
  update,
  setMerchants,
  setCurrentMerchant,
  setProducts,
  setMerchantLogo,
  setMerchantAgreements,
  removeMerchant,
  setMerchantIntegrations,
  removeMerchantIntegrations,
} = merchantSlice.actions;

export const getMerchantsAsync = (): AppThunk => async (dispatch) => {
  try {
    dispatch(loading(true));
    const data = await MerchantService.getApiMerchant();
    dispatch(setMerchants(data));
    dispatch(loading(false));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const getMerchantAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loadingMerchant(true));
      const data = await MerchantService.getApiMerchant1(id);
      console.log(data);
      dispatch(setCurrentMerchant(data));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
      console.log(e);
    }
  };

export const getMerchantProductsAsync =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const data = await MerchantService.getApiMerchantProduct(id);
      dispatch(setProducts(data));
      dispatch(loading(false));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
  };

export const createMerchantAsync =
  (merchant: MerchantDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const response = await MerchantService.postApiMerchant(merchant);
      dispatch(update(response));
      return response;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const updateMerchantAsync =
  (merchant: MerchantDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const response = await MerchantService.putApiMerchant(merchant.id!, merchant);
      dispatch(update(response));
      return response;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const removeMerchantAsync =
  (merchantId: string): AppThunk =>
  async (dispatch) => {
    try {
      await MerchantService.deleteApiMerchant(merchantId);
      dispatch(removeMerchant(merchantId));
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const updateMerchantLogoAsync =
  (merchant: MerchantDto, file: Blob): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const logo = await MerchantService.putApiMerchantLogo(merchant.id!, { file });
      dispatch(setMerchantLogo(logo));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const createMerchantLogoAsync =
  (merchant: MerchantDto, file: Blob): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const logo = await MerchantService.postApiMerchantLogo(merchant.id!, { file });
      dispatch(setMerchantLogo(logo));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const createMerchantAgreementAsync =
  (merchantId: string, agreement: AgreementDto): AppThunk =>
  async (dispatch) => {
    try {
      console.log(agreement);
      const result = await MerchantService.postApiMerchantAgreement(merchantId, agreement);
      dispatch(setMerchantAgreements({ id: agreement.merchantId!, agreements: result }));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      throw e;
    }
  };

export const approveMerchantAsync =
  (merchantId: string): AppThunk =>
  async (dispatch) => {
    try {
      const merchant = await MerchantService.postApiMerchantApprove(merchantId);
      dispatch(update(merchant));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
    return true;
  };

export const setMerchantAsync =
  (merchantId: string, ownerId: string): AppThunk =>
  async (dispatch) => {
    try {
      const merchant = await MerchantService.postApiMerchantOwner(merchantId, ownerId);
      dispatch(update(merchant));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      return false;
    }
  };

export const updateAddresses =
  (merchantId: string, addresses: AddressesDto): AppThunk =>
  async (dispatch) => {
    try {
      const merchant = await MerchantService.putApiMerchantAddresses(merchantId, addresses);
      dispatch(update(merchant));
      return true;
    } catch (e) {
      dispatch(hasError((e as Error).message));
      return false;
    }
  };

export const merchantStateSelector = (state: RootState): MerchantState => state.merchant;

export default merchantSlice.reducer;
