/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VoucherDto } from '../models/VoucherDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VoucherService {

    /**
     * @returns VoucherDto OK
     * @throws ApiError
     */
    public static getApiVoucher(): CancelablePromise<Array<VoucherDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Voucher',
        });
    }

    /**
     * @param id 
     * @returns VoucherDto OK
     * @throws ApiError
     */
    public static getApiVoucher1(
id: string,
): CancelablePromise<VoucherDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Voucher/{id}',
            path: {
                'id': id,
            },
        });
    }

}
