import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import {
  ApiError,
  VoucherDto,
  VoucherService,
} from 'src/api';

export interface VoucherState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  vouchers: VoucherDto[];
}

const initialState: VoucherState = {
  loading: false,
  hasError: false,
  error: '',
  vouchers: [],
};

export const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.hasError = false;
      }
      state.loading = action.payload;
    },
   
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setVouchers: (state, action: PayloadAction<VoucherDto[]>) => {
      state.vouchers = action.payload;
    },
    updateVoucher: (state, action: PayloadAction<VoucherDto>) => {
      const voucherToUpdate = state.vouchers?.find((voucher) => voucher.id === action.payload.id);

      const updatedVoucher = {...voucherToUpdate}
      updatedVoucher.isRedeemed = action.payload.isRedeemed;
      updatedVoucher.redeemedAt = action.payload.redeemedAt;
      
      state.vouchers = state.vouchers.map((voucher) => (voucher.id === action.payload.id? updatedVoucher: voucher))
    },
    
  },
});

export const {
  loading,
  hasError,
  setVouchers,
  updateVoucher,

} = voucherSlice.actions;

export const getVouchersAsync =
  (): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(loading(true));
      const response = await VoucherService.getApiVoucher();
      dispatch(setVouchers(response));
      
    } catch (e) {
      dispatch(hasError(((e as ApiError).body)));
    }
    finally
    {
      dispatch(loading(false));
    }
  };

  

export const voucherStateSelector = (state: RootState): VoucherState => state.voucher;

export default voucherSlice.reducer;
