import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from 'src/store/index';

import { fetchStart, fetchSuccess, fetchError } from 'src/store/slices/commonSlice';
import { MobileUserDto, MobileUserService } from 'src/api';

export interface MobileUserState {
  loading: boolean;
  hasError: boolean;
  error?: string;
  users: MobileUserDto[];
  user: MobileUserDto | null;
}

const initialState: MobileUserState = {
  users: [],
  user: null,
  loading: false,
  hasError: false,
  error: '',
};

export const mobileUserSlice = createSlice({
  name: 'mobileUser',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    hasError: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = `${action.payload}`;
    },
    setUsers: (state, action: PayloadAction<MobileUserDto[]>) => {
      state.users = action.payload;
    },
    setUser: (state, action: PayloadAction<MobileUserDto>) => {
      state.user = action.payload;
    },
    update: (state, action: PayloadAction<MobileUserDto>) => {
      state.user = action.payload;
      state.users = state.users.map((user) =>
        user.id === action.payload.id ? action.payload : user
      );
    },
    saving: (state) => {
      state.hasError = false;
      state.error = '';
    },
  },
});

export const { loading, saving, hasError, update, setUser, setUsers } = mobileUserSlice.actions;

export const loadMobileUsersAsync = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(loading(true));
    const data = await MobileUserService.getApiMobileuser();
    dispatch(setUsers(data));
    dispatch(loading(false));
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};
export const loadMobileUserAsync = (id: string): AppThunk => async (dispatch, getState) => {
  try {
   
    const data = await MobileUserService.getApiMobileuser1(id)
    dispatch(setUser(data));
    
  } catch (e) {
    dispatch(hasError((e as Error).message));
  }
};

export const onUpdateMobileUser =
  (user: MobileUserDto): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(saving());
      const userResult = await MobileUserService.postApiMobileuser(user!.id!, user);

      dispatch(update(userResult));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
    return true;
  };

export const onRemoveMobileUser =
  (userId: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(fetchStart());
      // const result = await UsersService.deleteApiUsers(eventId);

      // dispatch(update(result));
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError((e as Error).message));
    }
  };

export const onActivateBetaForMobileUser =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      const userResult = await MobileUserService.postApiMobileuserActivateBeta(userId);
      dispatch(update(userResult));
    } catch (e) {
      dispatch(hasError((e as Error).message));
    }
    return true;
  };

export const mobileUserStateSelector = (state: RootState): MobileUserState => state.mobileUser;

export default mobileUserSlice.reducer;
